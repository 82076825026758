$primary: #282828;
$secondary: #f6821f;

.container {
  width: inherit;
  height: inherit;
  background-image: url('https://i.pinimg.com/originals/0f/f2/97/0ff297c086b7ff4a5bd5a60303e58a5b.jpg');
  background-position: 55% 65%;
  background-size: cover;
}

.background {
  background-color: rgba($color: #282828, $alpha: 0.7);
}
