.container {
  width: inherit;
  height: inherit;
  background-image: url("https://i.pinimg.com/originals/0f/f2/97/0ff297c086b7ff4a5bd5a60303e58a5b.jpg");
  background-position: 55% 65%;
  background-size: cover;
  z-index: 9;
}

.background {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 2vh 0;
  background-color: rgba(40, 40, 40, 0.7);
  border-bottom: 3px solid #f6821f;
  z-index: 9;
}

.navbox {
  padding: 1.5vh 2.5vw;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.navhover:hover {
  cursor: pointer;
  color: #282828;
  -webkit-text-decoration: 2px underline solid #282828;
          text-decoration: 2px underline solid #282828;
}

.flexContainer {
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .flexContainer {
    display: 35%;
  }
}
