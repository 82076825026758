@import url('slick-carousel/slick/slick.css');
@import url('slick-carousel/slick/slick-theme.css');

.slide img {
  margin: 0 auto;
}

.slide {
  transform: scale(0.4);
  transition: transform 300ms;
  opacity: 0.6;
}

.activeSlide {
  transform: scale(1.1);
  opacity: 1;
}

.arrow {
  background-color: #f6821f;
  padding: 5px;
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
  z-index: 5;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}
