$primary: #282828;
$secondary: #f6821f;

.container {
  width: inherit;
  height: inherit;
  background-image: url('https://i.pinimg.com/originals/0f/f2/97/0ff297c086b7ff4a5bd5a60303e58a5b.jpg');
  background-position: 55% 65%;
  background-size: cover;
  z-index: 9;
}

.background {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vh 0;
  background-color: rgba($color: #282828, $alpha: 0.7);
  border-bottom: 3px solid $secondary;
  z-index: 9;
}

.navbox {
  padding: 1.5vh 2.5vw;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.navhover:hover {
  cursor: pointer;
  color: $primary;
  text-decoration: 2px underline solid $primary;
}

.flexContainer {
  width: 100%;

  @media only screen and (min-width: 1024px) {
    display: 35%;
  }
}
